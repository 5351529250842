<template>
  <div>
    <v-sheet
      :height="height"
      :width="width"
      color="transparent"
      style="position: relative"
    >
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarShadow"
      ></v-img>
      <v-img
      v-if="avatarBack && (selectedItem && selectedItem.category != 'back') || (avatarBack && !selectedItem) && selectedBack == null && !isHouse"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarBack"
      ></v-img>
      <v-img
        v-if="selectedItem && selectedItem.category == 'back'"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedItem.images).apparel.url"
      ></v-img>
      <v-img
        v-if="selectedBack"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedBack.images).apparel.url"
      ></v-img>
      <v-img
        v-if="avatarRight && (selectedItem && selectedItem.category != 'right') || (avatarRight && !selectedItem) && selectedRight == null && !isHouse"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarRight"
      ></v-img>
      <v-img
        v-if="selectedItem && selectedItem.category == 'right'"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedItem.images).apparel.url"
      ></v-img>
      <v-img
        v-if="selectedRight"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedRight.images).apparel.url"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarBody"
      >
        <template v-slot:placeholder>
          <v-img
            class="avatar"
            :max-height="height"
            :max-width="width"
            :src="require('@/assets/entity/load.png')"
          />
        </template>
      </v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :style="
          'filter : opacity(0.5) drop-shadow(0 0 0 #' + avatarColor + ' )'
        "
        :src="avatarBody"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarCheek"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarOutline"
      >
        <template v-slot:placeholder>
          <v-img
            class="avatar"
            :max-height="height"
            :max-width="width"
            :src="require('@/assets/entity/load.png')"
          />
        </template>
      </v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarMouth"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarEye"
      ></v-img>
      <v-img
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarHair"
      ></v-img>
      <v-img
        v-if="selectedItem && selectedItem.category == 'hat'"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedItem.images).apparel.url"
      ></v-img>
      <v-img
        v-if="avatarHat && (selectedItem && selectedItem.category != 'hat') || (avatarHat && !selectedItem) && selectedHat == null && !isHouse"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarHat"
      ></v-img>
      <v-img
        v-if="selectedHat"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedHat.images).apparel.url"
      ></v-img>
      <v-img
      v-if="avatarLeft && (selectedItem && selectedItem.category != 'left') || (avatarLeft && !selectedItem) && selectedLeft == null && !isHouse"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="avatarLeft"
      ></v-img>
      <v-img
        v-if="selectedItem && selectedItem.category == 'left'"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedItem.images).apparel.url"
      ></v-img>
      <v-img
        v-if="selectedLeft"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="JSON.parse(selectedLeft.images).apparel.url"
      ></v-img>
      <!-- <v-img
        v-if="previewHat != null"
        class="avatar"
        :max-height="height"
        :max-width="width"
        :src="
          hatData.filter(function (entry) {
            return entry.category == 'hat';
          })[0].key
        "
      ></v-img> -->
    </v-sheet>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    avatar: {
      type: String,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    changeHat: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
    previewHat: {
      type: String,
      default: null,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    selectedBack: {
      type: Object,
      default: null,
    },
    selectedRight: {
      type: Object,
      default: null,
    },
    selectedLeft: {
      type: Object,
      default: null,
    },
    selectedHat: {
      type: Object,
      default: null,
    },
    isHouse: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapState({
    skinData: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
  }),
  watch: {
    avatar: function () {
      this.getAvatar();
    },
  },
  data: () => ({
    avatarBack: null,
    avatarRight: null,
    avatarLeft: null,
    renderAvatar: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    left: [],
    right: [],
    back: [],
    version: null,
    selectedStudent: null,
    student: null,
    code: null,
    skin: [],
  }),
  created() {},
  mounted() {
    this.getAvatar();
  },
  methods: {
    getAvatar() {
      this.renderAvatar = JSON.parse(this.avatar);
      this.version = this.renderAvatar.charKey;
      if (this.version == "mochi_v4") {
        this.skin = this.skinData;
        this.selectSkin();
      }
    },
    selectSkin() {
      this.body = this.skin.body;
      this.outline = this.skin.outline;
      this.mouth = this.skin.mouth;
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;
      var outline = this.renderAvatar.body;
      var mouth = this.renderAvatar.mouth;
      var hat = this.renderAvatar.hat;
      var left = this.renderAvatar.left;
      var right = this.renderAvatar.right;
      var back = this.renderAvatar.back;
      var avatarOutline = this.outline.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarOutline = JSON.parse(avatarOutline[0].images).F0.url;
      var avatarBody = this.body.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarBody = JSON.parse(avatarBody[0].images).F0.url;
      var avatarMouth = this.mouth.filter(function (entry) {
        return entry.character == mouth;
      });
      this.avatarMouth = JSON.parse(avatarMouth[0].images).F0.url;

      if (hat) {
        var avatarHat = this.hatData.filter(function (entry) {
          return entry.key == hat;
        });
        this.avatarHat = JSON.parse(avatarHat[0].images).apparel.url;
      }
      if (left) {
        var avatarLeft = this.hatData.filter(function (entry) {
          return entry.key == left;
        });
        this.avatarLeft = JSON.parse(avatarLeft[0].images).apparel.url;
      }
      if (right) {
        var avatarRight = this.hatData.filter(function (entry) {
          return entry.key == right;
        });
        this.avatarRight = JSON.parse(avatarRight[0].images).apparel.url;
      }
      if (back) {
        var avatarBack = this.hatData.filter(function (entry) {
          return entry.key == back;
        });
        this.avatarBack = JSON.parse(avatarBack[0].images).apparel.url;
      }

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarColor = this.renderAvatar.pColor;
    },
  },
};
</script>

<style scoped>
.avatar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>